// extracted by mini-css-extract-plugin
export var awards = "company__awards-yCEYc";
export var block = "company__block-oOXXK";
export var block_blur = "company__block_blur-8Qp-m";
export var col = "company__col-5ZeEa";
export var definition = "company__definition-nfhTF";
export var definition__description = "company__definition__description-Sa338";
export var definition__term = "company__definition__term-yn77Z";
export var heading = "company__heading-2bMSb";
export var hero = "company__hero-izfKv";
export var hero__img = "company__hero__img-K9e4j";
export var hero__title = "company__hero__title-ae4iO";
export var map = "company__map-UA5gx";
export var member = "company__member-DdqTr";
export var member__divider = "company__member__divider-cJO3M";
export var member__list = "company__member__list-iUyQF";
export var member__text = "company__member__text-liWO1";
export var member__title = "company__member__title-S7c2c";
export var profiles = "company__profiles-Csnk+";
export var row = "company__row-r8K5C";
export var section = "company__section-DaeH0";
export var sectionDistance = "company__sectionDistance-1WUAq";
export var slogan = "company__slogan-ntP1U";
export var slogan__inner = "company__slogan__inner-IWQEE";
export var slogan__text = "company__slogan__text-fQEG0";
export var slogan__title = "company__slogan__title-hAyPu";
export var whiteLabelText = "company__whiteLabelText-b6ipq";