import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { SiteLayout, Head, Profile, Award, Marker, AizulabMap } from '~/components';
import * as company from '~/pages/company/company.module.scss';

const CompanyPage = () => {
  const [aizu, setAizu] = useState(false);
  const [aict, setAict] = useState(false);

  function onChildMouseEnter(enterKey) {
    if (enterKey === 0) {
      setAizu(true);
    } else if (enterKey === 1) {
      setAict(true);
    }
  }

  function onChildMouseLeave(leaveKey) {
    if (leaveKey === 0) {
      setAizu(false);
    } else if (leaveKey === 1) {
      setAict(false);
    }
  }

  return (
    <SiteLayout>
      <Head title="会社情報" />

      <StaticImage
        alt=""
        src="./images/hero.png"
        className={company.hero__img}
      />

      <div className={company.hero}>
        <h1 className={company.hero__title}>会社情報</h1>
      </div>

      <div className={company.slogan}>
        <div className={company.slogan__inner}>
          <h2 className={company.slogan__title}>“to Advance Knowledge for Humanity”</h2>
          <p className={company.slogan__text}>私たちは会津盆地という周りを自然で囲まれた恵まれた環境で、知的創造を追求している会津大学発ベンチャー企業です。当社ではiOS/Androidアプリケーションなど、スマートフォン向けのアプリケーションソフトウェアの開発を主に行っています。</p>
          <p className={company.slogan__text}>「to Advance Knowledge」は１９００年に全米大学協会がスローガンとして掲げ、まさしくこのスローガン通りに知識は大きく前進しました。<br />
            「結果的に戦争の為の、人を地球を壊す技術を作ってしまった」<br />
            「知識を伸ばすのは良いが人類の為になる知識を伸ばそう」（会津大学初代学長國井利泰先生談）<br />
            これが会津大学建学の理念である「to Advance Knowledge for Humanity」人類の為の知識の前進であります。<br />
            我々は会津大学発ベンチャーとして、会津大学建学の理念を掲げ、人類の為になる高度な知識と技術を世に送り出します。</p>
        </div>
      </div>

      <div className={company.block}>
        <section className={company.section}>
          <h2 className={company.heading}>会社情報</h2>
          <div className={company.row} style={{ maxWidth: 1006 }}>
            <div className={company.col}>
              <dl className={company.definition}>
                <dt className={company.definition__term}>会社名</dt>
                <dd className={company.definition__description}>株式会社会津ラボ / Aizu Laboratory, Inc.</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>設立年月</dt>
                <dd className={company.definition__description}>2007年1月4日</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>資本金</dt>
                <dd className={company.definition__description}>29,915,000円</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>代表取締役</dt>
                <dd className={company.definition__description}>松永 州央</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>所在地</dt>
                <dd className={company.definition__description}>本社<br />
                  〒965-0059 福島県会津若松市インター西５３番地<br />
                  TEL 0242-23-8285 FAX 0242-23-8286<br /><br />
                  AiCTオフィス<br />
                  〒965-0872 福島県会津若松市東栄町１−７７<br />
                  スマートシティＡｉＣＴ内</dd>
              </dl>
            </div>
            <div className={company.col}>
              <dl className={company.definition}>
                <dt className={company.definition__term}>営業時間</dt>
                <dd className={company.definition__description}>9：00～18：00<br />6月第1月曜～9月第4金曜は8：30～17：30</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>休業日</dt>
                <dd className={company.definition__description}>土・日、祝日、年末年始、夏期休暇、ＧＷ</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>事業内容</dt>
                <dd className={company.definition__description}>コンピュータに係わる新技術の研究開発</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>取引銀行</dt>
                <dd className={company.definition__description}>東邦銀行 会津アピオ支店<br />みずほ銀行 会津支店</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>株主</dt>
                <dd className={company.definition__description}><a target="_blank" rel="noopener noreferrer" href="https://4qualia.co.jp/">株式会社フォー・クオリア(主要株主)</a></dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>認証登録</dt>
                <dd className={company.definition__description}>
                  <StaticImage
                    alt=""
                    src="./images/logo_isms.png"
                    width={130}
                  />
                </dd>
              </dl>
            </div>
          </div>
        </section>
      </div>
      <div className={company.map}>
        <AizulabMap onChildMouseEnter={enterData => onChildMouseEnter(enterData)} onChildMouseLeave={leaveData => onChildMouseLeave(leaveData)}>
          <Marker
            aizu={aizu}
            alt="本社の場所"
            lat={37.522718}
            lng={139.913635}
          />
          <Marker
            aict={aict}
            alt="AiCTオフィスの場所"
            lat={37.492359}
            lng={139.930606}
          />
        </AizulabMap>
      </div>
      <div className={company.block_blur}>
        <section className={company.section}>
          <h2 className={company.heading}>受賞歴・商標登録</h2>
          <div className={company.awards}>
            <Award
              className={company.whiteLabelText}
              description="HASURAwardsの'Open Source Hero'を受賞しました。"
              date="2022.6"
            >
              <div >
                <StaticImage
                  alt=""
                  src="../../../content/assets/logo_hasura_awards.png"
                  width={330}
                  height={190}
                  quality={100}
                />
              </div>
            </Award>
            <Award
              description="「地域未来牽引企業」（経済産業省）に選定されました。"
              date="2018.12"
            >
              <StaticImage
                alt=""
                src="./images/logo_chiiki_kenin_kigyou.png"
                width={330}
                height={190}
                quality={100}
              />
            </Award>
            <Award
              description="第２回ふくしま産業賞（福島民報社主催） 特別賞受賞を受賞しました。"
              date="2016.12"
            >
              <StaticImage
                alt=""
                src="./images/logo_fukushima_sangyo.png"
                width={330}
                height={190}
                quality={100}
              />
            </Award>
            <Award
              description="「簡易設置可能な家庭向け多機能電力見える化システム」 会津産IT技術認定 大賞受賞"
              date="2016.11"
            >
              <StaticImage
                alt=""
                src="./images/logo_aizu_it.png"
                width={330}
                height={190}
                quality={100}
              />
            </Award>
            <Award
              description="「SMART PLUG」商標登録 登録6090241を取得しました。"
              date="2018.10"
            >
              <StaticImage
                alt=""
                src="./images/logo_smartplug.png"
                width={330}
                height={190}
                quality={100}
              />
            </Award>
            <Award
              description="「通信機能付き電源タップ」 意匠登録 第1564668号を取得しました。"
              date="2016.11"
            >
              <StaticImage
                alt=""
                src="./images/logo_dengentap.png"
                width={330}
                height={190}
                quality={100}
              />
            </Award>
            {/* <Award
              description="「指さしナビ」商標登録 第5543389号を取得しました。"
              date="2016.12"
            >
              <StaticImage
                alt=""
                src="./images/logo_yubisashinavi.png"
                width={330}
                height={190}
                quality={100}
              />
            </Award> */}
          </div>
        </section>
        <section className={company.section}>
          <h2 className={company.heading}>沿革</h2>
          <div className={company.row} style={{ maxWidth: 600 }}>
            <div className={company.col}>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2007年1月</dt>
                <dd className={company.definition__description}>株式会社NSTラボを設立<br />本社を会津若松市一箕町鶴賀に置く</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2008年1月</dt>
                <dd className={company.definition__description}>「ウェブアプリーケーション診断ASP」 会津産IT技術認定 大賞受賞</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2012年1月</dt>
                <dd className={company.definition__description}>「天気戯画」 会津産IT技術認定</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2012年4月</dt>
                <dd className={company.definition__description}>商号を株式会社会津ラボに変更<br />業務拡大のため、本社を会津若松市中央三丁目へ移転</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2012年12月</dt>
                <dd className={company.definition__description}>「指さしナビ」商標登録 第5543389号</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2013年1月</dt>
                <dd className={company.definition__description}>「ご当地観光指さしナビ」 会津産IT技術認定</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2013年2月</dt>
                <dd className={company.definition__description}>業務拡大のため、本社を会津若松市インター西へ移転</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2013年10月</dt>
                <dd className={company.definition__description}>第一回トンネルシアターアニメーションコンテスト（株式会社会津鉄道主催） 最優秀賞受賞</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2014年1月</dt>
                <dd className={company.definition__description}>「Apoli」 会津産IT技術認定 大賞受賞</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2014年11月</dt>
                <dd className={company.definition__description}>日本エンタープライズ株式会社の子会社となる</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2016年2月</dt>
                <dd className={company.definition__description}>「簡易設置可能な家庭向け多機能電力見える化システム」 会津産IT技術認定 大賞受賞</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2016年11月</dt>
                <dd className={company.definition__description}>「通信機能付き電源タップ」 意匠登録 第1564668号</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2016年12月</dt>
                <dd className={company.definition__description}>第２回ふくしま産業賞（福島民報社主催） 特別賞受賞</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2018年12月</dt>
                <dd className={company.definition__description}>経済産業省「地域未来牽引企業」に選定</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2020年4月</dt>
                <dd className={company.definition__description}>AiCTオフィスを開設</dd>
              </dl>
              <dl className={company.definition}>
                <dt className={company.definition__term}>2021年8月</dt>
                <dd className={company.definition__description}>株式会社フォー・クオリアの子会社となる</dd>
              </dl>
            </div>
          </div>
        </section>
      </div>
      <div className={company.block}>
        <section className={company.sectionDistance}>
          <h2 className={company.heading}>役員紹介</h2>
          <div className={company.profiles}>
            <Profile
              name="松永 州央"
              ruby="Kunihisa Matsunaga"
              degree="代表取締役"
              message="ソフトウェア開発経験の中でプログラミングだけではなく、オブジェクト指向設計、テスト手法、プロジェクト管理等の開発全般を手掛けて参りました。これまでの経験を生かし、現状に満足することなくお客様のわがままを最大限に取り入れたシステム作りを目指しています。<br />特にスクラム開発においては、所属するメンバー自身がイテレーションごとに計画を立案・設計・実装するため、メンバー同士でしっかりコミュニケーションをとって情報を共有することを重視しています。<br />品質を保証し、柔軟な機能拡張を実現し、長く使えるシステム、つまり変化に強いシステムを追求していきます。"
            >
              <StaticImage
                alt=""
                src="./images/pic_matsunaga.jpg"
                quality={100}
              />
            </Profile>
            <Profile
              name="常世 仁一"
              ruby="Tokoyo Jinichi"
              degree="取締役 クリエーション事業部"
              message="「千里の道も一歩から」
              大きな目標も最初の一歩から始まります。目標は大きなものや小さなもの、長期的なものや短期的なものと様々あります。しかし一歩を踏み出さない限り目標にも到達することはありませんし、壁にぶつかり歩みを止めてしまっても同じく目標には到達できません。
              私は取締役という経営に携わる立場で歩み出しております。時には歩みの方向が目標から外れ、壁にぶつかることもあるでしょう。そんな時はこの会津ラボの仲間とともに歩みの方向を確認し、止まることなく一歩ずつ歩んで参りたいと考えています。"
            >
              <StaticImage
                alt=""
                src="./images/pic_tokoyo.jpg"
                quality={100}
              />
            </Profile>
            <Profile
              name="遠藤 貴幸"
              ruby="Endou Takayuki"
              degree="取締役 ソリューション事業部"
              message="ソフトウェア開発経験の中でプログラミングだけではなく、オブジェクト指向設計、テスト手法、プロジェクト管理等の開発全般を手掛けて参りました。これまでの経験を生かし、現状に満足することなくお客様のわがままを最大限に取り入れたシステム作りを目指しています。<br />特にスクラム開発においては、所属するメンバー自身がイテレーションごとに計画を立案・設計・実装するため、メンバー同士でしっかりコミュニケーションをとって情報を共有することを重視しています。<br />品質を保証し、柔軟な機能拡張を実現し、長く使えるシステム、つまり変化に強いシステムを追求していきます。"
            >
              <StaticImage
                alt=""
                src="./images/pic_endo.jpg"
                quality={100}
              />
            </Profile>
          </div>
          {/* <div className={company.member}>
            <dl className={company.member__list}>
              <dt className={company.member__title}>非常勤取締役</dt>
              <dd className={company.member__text} data-ruby="Tanaka Masaru">田中 勝</dd>
              <dd className={company.member__text} data-ruby="Ueda Katsunori">植田 勝典</dd>
            </dl>
            <div className={company.member__divider} />
            <dl className={company.member__list}>
              <dt className={company.member__title}>監査役（非常勤）</dt>
              <dd className={company.member__text} data-ruby="Fukasawa Keitaro">深澤 恵太郎</dd>
            </dl>
          </div> */}
        </section>
      </div>
    </SiteLayout >
  )
}

export default CompanyPage;
